import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import '@fontsource/oswald';
import '@fontsource/oswald/variable.css';

import logo from './logo.png';
import heartlands from './heartlands.jpg';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import AlbumIcon from '@material-ui/icons/Album';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import EmailIcon from '@material-ui/icons/Email';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FacebookIcon from '@material-ui/icons/Facebook';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MuiTooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import YouTubeIcon from '@material-ui/icons/YouTube';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import vegard from './vegard.jpg';
import bendik from './bendik.jpg';
import gard from './gard.jpg';
import jon from './jon.jpg';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

function MenuButton({ open, ...props }) {
  return (
    <IconButton {...props}>
      {open ? <MenuOpenIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
    </IconButton>
  );
}

MenuButton.propTypes = {
  open: PropTypes.bool.isRequired,
};

const bodyBackgroundColor = '#fdef7f';

const useStyles = makeStyles((theme) => {
  return {
    menuPop: { paper: { width: '10rem' } },
    grid: {},
    bottomBar: {
      top: 'auto',
      bottom: 0,
      backgroundColor: bodyBackgroundColor,
    },
    topBar: {
      margin: 'auto',
      backgroundColor: bodyBackgroundColor,
      paddingTop: theme.spacing(1),
    },
  };
});

function Maldito() {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const closeDrawer = () => setMenuOpen(false);
  const history = useHistory();
  const styles = useStyles();
  const mobile = !useMediaQuery(`(min-width:${theme.spacing(90)}px)`);
  const [events, setEvents] = useState(null);

  useEffect(async () => {
    if (events === null) {
      let result = [];
      try {
        const res = await fetch('https://maldito.sout.no/bevents/');
        result = await res.json();
      } catch (error) {
        console.error(error);
      }
      result.reverse();
      setEvents(result);
    }
  }, [events]);

  const AppBarStyles = makeStyles({
    root: {
      position: 'relative',
      marginTop: mobile ? `${theme.spacing(10)}px` : '0',
      marginBottom: `${theme.spacing(4)}px`,
    },
  });

  const classes = AppBarStyles();

  return (
    <Container>
      <AppBar
        className={styles.topBar}
        position={mobile ? 'fixed' : 'static'}
        elevation={mobile ? 1 : 0}
      >
        <Toolbar
          style={{
            maxWidth: theme.spacing(120),
            ...(mobile ? {} : { padding: theme.spacing(1, 1) }),
          }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Button onClick={() => history.push('/')}>
                <img src={logo} style={{ width: '8rem' }} />
              </Button>
            </Grid>
            <Grid item>
              <MenuButton
                edge="end"
                id="menu-button"
                open={menuOpen}
                onClick={() => setMenuOpen(!menuOpen)}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        className={styles.grid}
        container
        classes={classes}
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        <Popover
          anchorEl={document.getElementById('menu-button')}
          className={styles.menuPop}
          open={menuOpen}
          onClose={closeDrawer}
        >
          <div
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
            style={{ maxWidth: '20rem' }}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <List style={{ width: '8rem' }}>
                  <ListItem onClick={() => history.push('/')} button>
                    HOME
                  </ListItem>
                  <ListItem onClick={() => history.push('/tour')} button>
                    TOUR
                  </ListItem>
                  <ListItem onClick={() => history.push('/shop')} button>
                    SHOP
                  </ListItem>
                  <ListItem onClick={() => history.push('/media')} button>
                    MEDIA
                  </ListItem>
                  <ListItem onClick={() => history.push('/contact')} button>
                    CONTACT
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </div>
        </Popover>
        <Grid item>
          <Switch>
            <Route path="/tour">
              <Tour events={events} />
            </Route>
            <Route path="/shop">
              <Shop />
            </Route>
            <Route path="/media">
              <Media />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <SoMeIcons />
    </Container>
  );
}

import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

const formatDate = (date) => {
  try {
    const concert_date = new Date(date);
    const options = {
      month: 'short',
      day: '2-digit',
    };
    return new Intl.DateTimeFormat('no', options).format(concert_date);
  } catch (e) {
    console.warn(e);
    return date;
  }
};

function Listen() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid direction="column" container alignItems="center" justify="center">
        <Grid item>
          <h1>STREAM OUR ALBUM</h1>
        </Grid>
        <Grid item>
          <Iframe
            src="https://ditto.fm/heartlands"
            style={{
              height: '1100px',
              borderRadius: '5px',
              width: '350px',
              overflow: 'hidden',
            }}
            scrolling="no"
          />
        </Grid>
      </Grid>
    </div>
  );
}

const formatTime = (date) => {
  try {
    const concert_date = new Date(date);
    const hasTime = concert_date.getHours() !== 0;
    if (!hasTime) {
      return null;
    }
    const options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('no', options).format(concert_date);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

TourDate.propTypes = {
  date: PropTypes.shape({
    start: PropTypes.date,
    end: PropTypes.date,
  }).isRequired,
  location: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  ticket_url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

function TourDate({ date, location, host, ticket_url, name, id, index }) {
  const [open, setOpen] = useState(
    index === 0 && new Date(date.start).getFullYear() === 2021,
  );
  const theme = useTheme();
  const handleClick = () => {
    setOpen(!open);
  };

  const formatted_time = formatTime(date.start);

  const time = formatted_time !== null ? `${formatted_time}` : '';

  let host_text = host;
  let list_text = host_text;
  if (host_text.length > 24) {
    list_text = host_text.substr(0, 20) + '...';
  }

  const Minutes = () => {
    if (open) {
      return '';
    }
    return (
      <>
        {formatDate(date.start)} {time}
        <br />
        {list_text}
      </>
    );
  };

  const onClickFacebook = () =>
    window.open(`https://www.facebook.com/events/${id}`, '_blank');

  return (
    <>
      <ListItem onClick={handleClick} button>
        <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        <ListItemText primary={location} secondary={<Minutes />} />
        {ticket_url.length > 0 && (
          <BlackButton
            text="get tickets"
            tooltip={ticket_url}
            onClick={(e) => {
              e.preventDefault();
              window.open(`${ticket_url}`, '_blank');
            }}
          />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Card onClick={handleClick}>
          <CardContent>
            <h1 style={{ marginTop: 0 }}>{host}</h1>
            <p>
              {formatDate(date.start)} {time}
            </p>
            {name}
          </CardContent>
          <CardActions>
            <BlackButton
              id="facebook-event-button"
              size="small"
              onClick={onClickFacebook}
              tooltip="Open event on Facebook"
              text={
                <>
                  <FacebookIcon />
                  <span style={{ marginLeft: theme.spacing(1) }}>Facebook</span>
                </>
              }
            />
            <BlackButton
              onClick={handleClick}
              text="close"
              tooltip="Close Event"
              size="small"
            />
          </CardActions>
        </Card>
      </Collapse>
    </>
  );
}

Tour.propTypes = {
  events: PropTypes.array,
};

function Tour({ events }) {
  if (events === null) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Grid direction="column" container alignItems="center" justify="center">
          <h1> 2021</h1>
        </Grid>
      </div>
      <List>
        {events
          .filter((event) => new Date(event.date.start).getFullYear() === 2021)
          .map((event, index) => (
            <TourDate {...event} key={`${index}-${event.id}`} index={index} />
          ))}
      </List>
    </>
  );
}

function Shop() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid direction="column" container alignItems="center" justify="center">
        <Grid item>
          <h1>SHOP</h1>
        </Grid>
        <Grid item>
          <img src={heartlands} style={{ width: '50%' }} />
        </Grid>
        <Grid item style={{ marginTop: '8px' }}>
          <h3> You can open the shop by clicking the button below</h3>
          <BlackButton
            text="Open SHOP"
            tooltip="Open Shop"
            onClick={() =>
              window.open(
                'https://dinide.no/produktkategori/maldito/',
                '_blank',
              )
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

const tooltipStyles = makeStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
  },
});

function Tooltip(props) {
  const classes = tooltipStyles();

  const tooltipProps = { ...props, classes };

  return <MuiTooltip {...tooltipProps} />;
}

BlackButton.propTypes = {
  text: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
};

function BlackButton({ text, tooltip, ...props }) {
  const theme = useTheme();
  return (
    <Tooltip title={tooltip}>
      <Button
        {...props}
        variant="contained"
        style={{
          marginBottom: theme.spacing(1),
          color: 'white',
          backgroundColor: 'black',
        }}
      >
        {text}
      </Button>
    </Tooltip>
  );
}

const red = '#ce153a';

Email.propTypes = {
  mail: PropTypes.string.isRequired,
};

function Email({ mail, ...props }) {
  return (
    <a {...props} style={{ color: red }} href={`mailto:${mail}`}>
      {mail}
    </a>
  );
}

function Contact() {
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid direction="column" container alignItems="center">
        <Grid item>
          <h1>BOOKING</h1>
          <h3>TROND JOHNSEN</h3>
          <h3>
            PHONE:{' '}
            <a style={{ color: red }} href="tel:004741402726">
              +47 414 02 726
            </a>
          </h3>{' '}
          <h3>
            <Email mail="trond@tjconcerts.no" />
          </h3>
        </Grid>
        <Grid item>
          <h1>OTHER INQUERIES</h1>
          <h3>MALDITO</h3>
          <h3>
            PHONE:{' '}
            <a style={{ color: red }} href="tel:004792081159">
              +47 920 81 159
            </a>
          </h3>
          <h3>
            <Email mail="post@maldito.no" />
          </h3>
        </Grid>
        <Grid item>
          <h1>PRESS</h1>
          <h3>
            Open our press release in your prefered language by cliking one of
            the buttons below.
          </h3>
          <BlackButton
            text="norsk"
            tooltip="Les presseskriv på norsk"
            onClick={() =>
              window.open('https://maldito.no/presse.pdf', '_blank')
            }
          />{' '}
          <BlackButton
            text="English"
            tooltip="Read our press release in English"
            onClick={() =>
              window.open('https://maldito.no/press.pdf', '_blank')
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
function ListenButton(props) {
  const history = useHistory();
  return (
    <BlackButton
      onClick={() => {
        history.push('/media');
      }}
      tooltip="Listen to album"
      text="LISTEN"
      {...props}
    />
  );
}
function Home() {
  const theme = useTheme();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  return (
    <div style={{}}>
      <div style={{ margin: '1rem' }}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12}>
            <img src={heartlands} style={{ width: '100%' }} />
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={8}>
              <h1 style={{ fontWeight: 400, justifyContent: 'center' }}>
                DEBUT ALBUM
                <span style={{ marginLeft: theme.spacing(2), fontWeight: 900 }}>
                  OUT NOW
                </span>
              </h1>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-around"
            >
              <Grid item>
                <ListenButton />
              </Grid>
              <Grid item>
                <BlackButton
                  tooltip="Open TOUR"
                  text="LIVE"
                  href="/tour"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/tour');
                  }}
                />
              </Grid>
              <Grid item>
                <BlackButton
                  tooltip="Buy Album"
                  text="Buy"
                  href="/buy"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/shop');
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={3}>
          <Card>
            <CardContent style={{ padding: '8px' }}>
              <img src={vegard} width="100%" />
              <h3>Vegard</h3>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ paddingLeft: '8px' }}
          id="bendiks"
          onmouseenter={() => setOpen(true)}
          onmouseleave={() => setOpen(false)}
        >
          <Card>
            <CardContent style={{ padding: '8px' }}>
              <img src={bendik} width="100%" />
              <h3>Bendik</h3>
            </CardContent>
          </Card>
        </Grid>
        <Popover
          anchorEl={document.getElementById('bendiks')}
          open={open}
          onClose={closeDrawer}
        >
          <div
            onClick={closeDrawer}
            onKeyDown={closeDrawer}
            style={{ maxWidth: '20rem', padding: '1rem' }}
          >
            <h1> Bendik</h1>
            <p>
              Lorem Ipsum er rett og slett dummytekst fra og for
              trykkeindustrien. Lorem Ipsum har vært bransjens standard for
              dummytekst helt siden 1500-tallet, da en ukjent boktrykker stokket
              en mengde bokstaver for å lage et prøveeksemplar av en bok. Lorem
              Ipsum har tålt tidens tann usedvanlig godt, og har i tillegg til å
            </p>
          </div>
        </Popover>

        <Grid item xs={3} style={{ paddingLeft: '8px' }}>
          <Card>
            <CardContent style={{ padding: '8px' }}>
              <img src={jon} width="100%" />
              <h3>Jon Olav</h3>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3} style={{ paddingLeft: '8px' }}>
          <Card>
            <CardContent style={{ padding: '8px' }}>
              <img src={gard} width="100%" />
              <h3>Gard</h3>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

Iframe.propTypes = {
  height: PropTypes.element.isRequired,
};

function Iframe({ height, ...props }) {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      {loading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: height }}
        >
          <Grid item>
            <CircularProgress style={{ color: 'black' }} />
          </Grid>
        </Grid>
      ) : null}
      <Fade in={!loading}>
        <iframe
          style={{ hidden: loading ? 'true' : 'false' }}
          {...{ height, ...props }}
          onLoad={() => setLoading(false)}
          frameBorder="0"
        ></iframe>
      </Fade>
    </div>
  );
}

function Media() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>YouTube</h1>
      <h3>Maldito – Dig the Rhythm of Your Heartbeat</h3>
      <Iframe
        height="150px"
        width="100%"
        src="https://www.youtube-nocookie.com/embed/ThflwzH_REg"
        title="Dig the Rhythm of Your Heartbeat"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <h3>Maldito – Trapped </h3>
      <Iframe
        height="150px"
        width="100%"
        src="https://www.youtube-nocookie.com/embed/jGFGw7qTPpE"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Listen />
    </div>
  );
}

const Sometooltip = ({ children }) => (
  <div style={{ textAlign: 'center' }}>
    {children}
    <br /> (opens in new tab)
  </div>
);

Sometooltip.propTypes = {
  children: PropTypes.string,
};

function SoMeIcons() {
  const history = useHistory();

  return (
    <Grid container justify="space-evenly" alignItems="flex-end">
      <SomeIcon tooltip="Contact us" onClick={() => history.push('/contact')}>
        <EmailIcon />
      </SomeIcon>
      <SomeIcon
        tooltip={<Sometooltip>Like us on Facebook</Sometooltip>}
        onClick={() =>
          window.open('https://www.facebook.com/bandmaldito', '_blank')
        }
      >
        <FacebookIcon />
      </SomeIcon>
      <SomeIcon
        tooltip={<Sometooltip>Watch us on YouTube</Sometooltip>}
        onClick={() =>
          window.open(
            'https://www.youtube.com/channel/UC4qkwWek9IQpibno5G0JzdA',
            '_blank',
          )
        }
      >
        <YouTubeIcon />
      </SomeIcon>
      <SomeIcon
        tooltip="Listen to our music"
        onClick={() => {
          history.push('/media');
        }}
      >
        <AlbumIcon />
      </SomeIcon>
      <SomeIcon
        tooltip={<Sometooltip>Follow us on Instagram</Sometooltip>}
        onClick={() =>
          window.open('https://www.instagram.com/malditoofficial', '_blank')
        }
      >
        <InstagramIcon />
      </SomeIcon>
    </Grid>
  );
}

SomeIcon.propTypes = {
  style: PropTypes.object,
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

function SomeIcon({ tooltip, style, ...props }) {
  return (
    <Grid item>
      <Tooltip title={tooltip}>
        <IconButton style={{ ...style, color: 'black' }} {...props} />
      </Tooltip>
    </Grid>
  );
}

function App() {
  const height = document.body.height * 0.8;
  const theme = useTheme();

  return (
    <ThemeProvider theme={createMuiTheme({ palette: { type: 'light' } })}>
      <div
        style={{
          maxWidth: theme.spacing(70),
          margin: 'auto',
          height: `${height}px`,
        }}
      >
        <Router>
          <Maldito />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
